import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Container from "react-bootstrap/Container"
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from "react-bootstrap/Col"
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'

import { calculateLayover, transformDuration, convertDateFormat, calcAmountDue } from '../utils/utils'
import AdminFooter from "../utils/AdminFooter"
import { baseUrl } from "../utils/Constants"

import Pool from '../auth/UserPool'
import { AccountContext } from "../auth/Account"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function AdminViewEventPage() {
    const location = useLocation()
    const navigation = useNavigate()
    const { logout } = useContext(AccountContext)

    const eventId = location.state?.event_id
    const userName = location.state?.userName
    const organizationName = location.state?.organizationName

    const [event, setEvent] = useState("")
    const [attendeeList, setAttendeeList] = useState([])

    // Auth state
    const [authToken, setAuthToken] = useState("")

    // View flight modal state
    const [showRefundModal, setShowRefundModal] = useState(false)
    const [showViewFlightModal, setShowViewFlightModal] = useState(false)
    const [flight, setFlight] = useState(null)
    const [additionalInfo, setAdditionalInfo] = useState("")

    // Show/hide modal
    const handleShowViewFlightModalShow = (attendee) => {
        fetch(baseUrl + "/client/lookup-order?" + new URLSearchParams({
            link_id: attendee.link_id
        }))
            .then(results => results.json())
            .then(data => {
                setFlight(data)
                setAdditionalInfo(attendee.additional_info)
            })
        setShowViewFlightModal(true)
    }

    const handleShowViewFlightModalHide = () => {
        setShowViewFlightModal(false)
        setFlight(null)
        setAdditionalInfo("")
    }

    const handleRefundModalShow = () => {
        setShowRefundModal(true)
    }

    const handleRefundModalHide = () => {
        setShowRefundModal(false)
    }

    useEffect(() => {
        if (!eventId) {
            navigation('/dashboard')
        } else {
            const user = Pool.getCurrentUser()
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        navigation('/sign-in')
                    } else {
                        setAuthToken(session.accessToken.jwtToken)
                    }
                })
            } else {
                navigation('/sign-in')
            }
        }
    }, [])

    useEffect(() => {
        if (authToken) {
            fetch(baseUrl + '/admin/get-single-event?' + new URLSearchParams({
                event_id: eventId
            }), {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(results => results.json())
                .then(data => {
                    setEvent(data)
                })
        }
    }, [authToken])

    useEffect(() => {
        if (authToken) {
            fetch(baseUrl + '/admin/get-all-people?' + new URLSearchParams({
                event_id: eventId
            }), {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(results => results.json())
                .then(data => {
                    setAttendeeList(data)
                })
        }
    }, [authToken])

    function handleLogout() {
        logout()
        navigation('/sign-in')
    }

    function calcTotal() {
        var total = 0.0
        for (var i = 0; i < attendeeList.length; i++) {
            total += parseFloat(attendeeList[i]["max_cost"])
        }
        return total
    }

    function calcUsed() {
        var total = 0.0
        for (var i = 0; i < attendeeList.length; i++) {
            total += Math.min(parseFloat(attendeeList[i]["actual_cost"]), parseFloat(attendeeList[i]["max_cost"]))
        }
        return total
    }

    function calcAmountDue() {
        return (calcTotal() * event.pay_now_percent / 100) - event.amount_paid
    }

    function handleExportAttendees() {
        let rows = []
        let header_row = ["first_name", "last_name", "email", "origin_airport", "return_airport", "is_booked", "max_cost_dollars"]
        rows.push(header_row)
        for (var i = 0; i < attendeeList.length; i++) {
            let row = []
            row.push(attendeeList[i]["link_first_name"])
            row.push(attendeeList[i]["link_last_name"])
            row.push(attendeeList[i]["link_email"])
            row.push(attendeeList[i]["origin_airport"])
            row.push(attendeeList[i]["return_airport"])
            row.push(attendeeList[i]["is_booked"])
            row.push(attendeeList[i]["max_cost"])
            rows.push(row)
        }
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n")

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "planelinks_export.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "planelinks_export.csv"
    }

    const OverlayTooltip = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            {children}
        </OverlayTrigger>
    )

    return (
        <div>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/">✈️ <b>Planelinks</b> | View Past Event</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Link to="/dashboard" className="no-style-link-light" state={{ email: userName, organizationName: organizationName }}>
                            <Button variant="success" className="log-in-button" style={{ marginRight: '32px' }}>
                                Back to Dashboard
                            </Button>
                        </Link>
                        <Button variant="dark" className="log-in-button" onClick={() => handleLogout()}>
                            Log Out
                        </Button>
                    </Navbar.Collapse>
                </Navbar>
                <div className="admin-dashboard-landing">
                    <Row>
                        <Col md={6}>
                            <h3>View Your Event:</h3>
                            <h4>{event.event_name}</h4>
                            <p>Location: {event.event_location}<br />
                                Airport: {event.pref_airports}<br />
                                {event.event_start} to {event.event_end} (Event)<br />
                                {event.fly_in_date} to {event.fly_out_date} (Flights)</p>
                        </Col>
                        <Col md={6}>
                            <h3>At a Glance:</h3>
                            <Row>
                                <Col>
                                    <p>Number of attendees: {attendeeList.length}<br />
                                        Number of attendees paid for: {event.num_paid_links}</p>
                                </Col>
                                <Col>
                                    <p>Percentage paid upfront: {event.pay_now_percent}%<br />
                                        Total budget: ${calcTotal()}<br />
                                        Amount paid: ${event.amount_paid}<br />
                                        Amount used: ${calcUsed()}</p>
                                    <Button className="log-in-button" onClick={() => handleRefundModalShow()} disabled={event.amount_paid <= calcUsed()}>
                                        Request Refund
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <hr />
                <div className='admin-dashboard-landing'>
                    <h3>Attendees:</h3>
                    <Button className="log-in-button" style={{ marginTop: '1vh', marginBottom: '1vh', marginRight: '8px' }} onClick={() => handleExportAttendees()} disabled={attendeeList.length <= 0}>
                        Export Attendees
                    </Button>
                    <div className="attendee-table">
                        <Table responsive hover className="text-align-center">
                            <thead>
                                <tr>
                                    <OverlayTooltip title="First/given name of attendee" id="t-1">
                                        <th>First Name</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Last/family name of attendee" id="t-2">
                                        <th>Last Name</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Email address of attendee" id="t-3">
                                        <th>Email</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Airport the attendee is flying from" id="t-4">
                                        <th>Origin Airport</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Airport the attendee is returning to" id="t-5">
                                        <th>Return Airport</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Maximum budget for this attendee" id="t-6">
                                        <th>Max Cost</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Actual amount spent for this attendee" id="t-7">
                                        <th>Actual Cost</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Has this attendee booked a flight?" id="t-8">
                                        <th>Booked Flight</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Click link to manage/edit attendee" id="t-9">
                                        <th>View Flight</th>
                                    </OverlayTooltip>
                                </tr>
                            </thead>
                            <tbody>
                                {attendeeList.map(function (attendee) {
                                    return (
                                        <tr key={attendee.link_id}>
                                            <td>{attendee.link_first_name}</td>
                                            <td>{attendee.link_last_name}</td>
                                            <td>{attendee.link_email}</td>
                                            <td>{attendee.origin_airport}</td>
                                            <td>{attendee.return_airport}</td>
                                            <td>${attendee.max_cost}</td>
                                            <td>${attendee.actual_cost}</td>
                                            <td>{attendee.is_booked === true ? "✔️" : "❌"}</td>
                                            <td><Button onClick={() => handleShowViewFlightModalShow(attendee)} disabled={!attendee.is_booked}>
                                                View
                                            </Button>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <hr />
                <AdminFooter />
            </Container>
            <Modal size='lg' show={showRefundModal} onHide={handleRefundModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Request Refund</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>To request a refund, please send an email to <Link to="mailto:refunds@planelinks.com">refunds@planelinks.com</Link> with the following information:</p>
                    <ul>
                        <li>User name: {userName}</li>
                        <li>Organization name: {organizationName}</li>
                        <li>Event name: {event.event_name}</li>
                        <li>Event ID: {event.event_id}</li>
                        <li>Amount paid: ${event.amount_paid}</li>
                        <li>Amount used: ${calcUsed()}</li>
                    </ul>
                    <p>The team will reach out in a few days to verify this information and process your refund.</p>
                </Modal.Body>
            </Modal>
            <Modal size='lg' show={showViewFlightModal} onHide={handleShowViewFlightModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>View Booked Flight</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Total price of ticket: ${flight && flight.outbound_flight.price}</p>
                    <p>Additional information: {additionalInfo}</p>
                    {flight && (<Table responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={flight.outbound_flight.offer_id}>
                                <td style={{ textAlign: "center" }}><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.outbound_flight.airline_img} alt="Airline Logo" /></td>
                                <td><p><b>{convertDateFormat(flight.outbound_flight.departure_time)} - {convertDateFormat(flight.outbound_flight.arrival_time)}</b><br />{flight.outbound_flight.airline_name} · {flight.outbound_flight.market_flight_no.join(", ")}</p></td>
                                <td><p><b>{transformDuration(flight.outbound_flight.duration)}</b><br />{flight.outbound_flight.origin_airport}-{flight.outbound_flight.destination_airport}</p></td>
                                <td><p>{calculateLayover(flight.outbound_flight.layover_airport)}</p></td>
                            </tr>
                            <tr key={flight.return_flight.offer_id}>
                                <td style={{ textAlign: "center" }}><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.return_flight.airline_img} alt="Airline Logo" /></td>
                                <td><p><b>{convertDateFormat(flight.return_flight.departure_time)} - {convertDateFormat(flight.return_flight.arrival_time)}</b><br />{flight.return_flight.airline_name} · {flight.return_flight.market_flight_no.join(", ")}</p></td>
                                <td><p><b>{transformDuration(flight.return_flight.duration)}</b><br />{flight.return_flight.origin_airport}-{flight.return_flight.destination_airport}</p></td>
                                <td><p>{calculateLayover(flight.return_flight.layover_airport)}</p></td>
                            </tr>
                        </tbody>
                    </Table>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleShowViewFlightModalHide}>
                        Back to Dashboard
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminViewEventPage
