import React, { useEffect, useState } from "react"
import { Link, useLocation } from 'react-router-dom'
import Container from "react-bootstrap/Container"
import Navbar from "react-bootstrap/Navbar"
import ClientFooter from '../utils/ClientFooter'
import { useTranslation } from 'react-i18next'
import Table from 'react-bootstrap/Table'
import Button from 'react-bootstrap/Button'
import { calculateLayover, transformDuration, convertDateFormat } from '../utils/utils'
import { baseUrl } from "../utils/Constants"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function ClientFinishPage() {
    const { t } = useTranslation()
    const location = useLocation()

    // View flight information state
    const [flight, setFlight] = useState(null)

    // Load the flight
    useEffect(() => {
        fetch(baseUrl + '/client/lookup-order?' + new URLSearchParams({
            link_id: location.state.link_id,
        }))
            .then(results => results.json())
            .then(data => {
                console.log("data")
                console.log(data.outbound_flight)
                setFlight(data)
            })
    }, [])

    return (
        <Container>
            <Navbar className="navbar-no-bg">
                <Navbar.Brand>✈️ <b>Planelinks</b> | {t("booking_confirmation_navbar")}</Navbar.Brand>
            </Navbar>
            <div className="admin-dashboard-landing" style={{ minHeight: "75vh" }}>
                <h3>{t("congrats_flights_booked")}<br />
                    {t("confirmation_email_to_send")}</h3>
                <p>{t("have_questions")}<br />
                    {t("reach_support")} <Link to="mailto:support@planelinks.com">support@planelinks.com</Link>.</p>
                <br />
                <br />
                <h3>{t("confirmation_code")}: {flight && flight.order_pnr}</h3>
                <h3>{t("your_flights")}:</h3>
                {flight && (<Table responsive>
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr key={flight.outbound_flight.offer_id}>
                            <td style={{ textAlign: "center" }}><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.outbound_flight.airline_img} alt="Airline Logo" /></td>
                            <td><p><b>{convertDateFormat(flight.outbound_flight.departure_time)} - {convertDateFormat(flight.outbound_flight.arrival_time)}</b><br />{flight.outbound_flight.airline_name} · {flight.outbound_flight.market_flight_no.join(", ")}</p></td>
                            <td><p><b>{transformDuration(flight.outbound_flight.duration)}</b><br />{flight.outbound_flight.origin_airport}-{flight.outbound_flight.destination_airport}</p></td>
                            <td><p>{calculateLayover(flight.outbound_flight.layover_airport)}</p></td>
                        </tr>
                        <tr key={flight.return_flight.offer_id}>
                            <td style={{ textAlign: "center" }}><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.return_flight.airline_img} alt="Airline Logo" /></td>
                            <td><p><b>{convertDateFormat(flight.return_flight.departure_time)} - {convertDateFormat(flight.return_flight.arrival_time)}</b><br />{flight.return_flight.airline_name} · {flight.return_flight.market_flight_no.join(", ")}</p></td>
                            <td><p><b>{transformDuration(flight.return_flight.duration)}</b><br />{flight.return_flight.origin_airport}-{flight.return_flight.destination_airport}</p></td>
                            <td><p>{calculateLayover(flight.return_flight.layover_airport)}</p></td>
                        </tr>
                    </tbody>
                </Table>)}
                <div className="text-align-center">
                    <Button variant="success" className="log-in-button" style={{ marginRight: "32px" }} onClick={() => { window.print() }}>
                        {t("print_page")}
                    </Button>
                    <Link to="/" className="no-style-link-light">
                        <Button variant="dark" className="log-in-button">
                            {t("back_to_homepage")}
                        </Button>
                    </Link>
                </div>
                <br />
                <br />
                <p>{t("convert_text_bottom")}<Link to="/book-demo">planelinks.com</Link>.</p>
            </div>
            <hr />
            <ClientFooter />
        </Container>
    )
}

export default ClientFinishPage
