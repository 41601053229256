import moment from 'moment'
import { sendEmailUrl } from './Constants'

export function calculateLayover(layover_list) {
    if (layover_list.length === 0) {
        return (
            <b>Nonstop</b>
        )
    } else if (layover_list.length === 1) {
        return (
            <>
                <b>1 stop</b><br />
                {layover_list[0]}
            </>
        )
    } else {
        return (
            <>
                <b>{layover_list.length} stops</b><br />
                {layover_list.toString()}
            </>
        )
    }
}

export function calculateStopsString(layover_list) {
    if (layover_list.length === 0) {
        return "Nonstop"
    } else if (layover_list.length === 1) {
        return "1 stop"
    } else {
        return layover_list.length + " stops"
    }
}

export function layoverListString(layover_list) {
    if (layover_list.length === 0) {
        return ""
    } else if (layover_list.length === 1) {
        return layover_list[0]
    } else {
        return layover_list.toString()
    }
}

export function transformDuration(duration) {
    const d = moment.duration(duration)
    var hours = d.hours()
    if (d.days() > 0) {
        hours += 24 * d.days()
    }
    return hours + " hr " + d.minutes() + " min"
}

export function convertDateFormat(inputDateString) {
    // Create a Date object from the input string
    const inputDate = new Date(inputDateString)

    // Define arrays for days and months
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    // Get the day, month, year, hours, and minutes from the input date
    const dayOfWeek = daysOfWeek[inputDate.getDay()];
    const dayOfMonth = inputDate.getDate();
    const month = months[inputDate.getMonth()];
    const year = inputDate.getFullYear();
    const hours = inputDate.getHours();
    const minutes = inputDate.getMinutes();

    // Format the date string
    const formattedDateString = `${dayOfWeek}, ${dayOfMonth} ${month} ${year}, ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`

    return formattedDateString
}

export function getIATAFromImg(img_url) {
    return img_url.substring(76, 78)
}

export function sendConfirmEmail(data) {
    const emailParams = {
        userFirstName: data.firstName,
        userLastName: data.lastName,
        userEmail: data.user_email,
        eventName: data.eventName,
        PNR: data.order_pnr,
        outboundImg: data.outboundFlight.airline_img,
        outboundTimes: convertDateFormat(data.outboundFlight.departure_time) + " - " + convertDateFormat(data.outboundFlight.arrival_time),
        outboundAirline: data.outboundFlight.airline_name,
        outboundDuration: transformDuration(data.outboundFlight.duration),
        outboundRoute: data.outboundFlight.origin_airport + " - " + data.outboundFlight.destination_airport,
        outboundStops: calculateStopsString(data.outboundFlight.layover_airport),
        outboundStopCodes: layoverListString(data.outboundFlight.layover_airport),
        outboundFlightNums: data.outboundFlight.market_flight_no.join(", "),
        returnImg: data.returnFlight.airline_img,
        returnTimes: convertDateFormat(data.returnFlight.departure_time) + " - " + convertDateFormat(data.returnFlight.arrival_time),
        returnAirline: data.returnFlight.airline_name,
        returnDuration: transformDuration(data.returnFlight.duration),
        returnRoute: data.returnFlight.origin_airport + " - " + data.returnFlight.destination_airport,
        returnStops: calculateStopsString(data.returnFlight.layover_airport),
        returnStopCodes: layoverListString(data.returnFlight.layover_airport),
        returnFlightNums: data.returnFlight.market_flight_no.join(", "),
    }

    fetch(sendEmailUrl + '/send-email-confirm', {
        method: "POST",
        mode: "cors",
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(emailParams)
    })
}
