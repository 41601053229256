import React, { useContext, useEffect, useState } from "react"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Container from "react-bootstrap/Container"
import Navbar from 'react-bootstrap/Navbar'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from "react-bootstrap/Col"
import Table from 'react-bootstrap/Table'
import Modal from 'react-bootstrap/Modal'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Form from 'react-bootstrap/Form'

import { calculateLayover, transformDuration, convertDateFormat } from '../utils/utils'
import { baseUrl, sendEmailUrl } from "../utils/Constants"
import AdminFooter from "../utils/AdminFooter"

import Pool from '../auth/UserPool'
import { AccountContext } from "../auth/Account"

import 'bootstrap/dist/css/bootstrap.min.css'
import '../styles/DashboardPage.css'
import '../styles/LandingPage.css'

function AdminManageEventPage() {
    const location = useLocation()
    const navigation = useNavigate()
    const { logout } = useContext(AccountContext)

    const eventId = location.state?.event_id

    // Event state, for top of page
    const [userName, setUserName] = useState("")
    const [organizationName, setOrganizationName] = useState("")
    const [event, setEvent] = useState("")
    const [attendeeList, setAttendeeList] = useState([])
    const [activeAttendee, setActiveAttendee] = useState("")

    // Edit event form state
    const [eventName, setEventName] = useState("")
    const [eventLocation, setEventLocation] = useState("")
    const [eventStartDate, setEventStartDate] = useState("")
    const [eventEndDate, setEventEndDate] = useState("")
    const [eventFlyInDate, setEventFlyInDate] = useState("")
    const [eventFlyOutDate, setEventFlyOutDate] = useState("")
    const [ebtnDisabled, setEBtnDisabled] = useState(false)
    const [evalidated, setEValidated] = useState(false)

    // Add attendee form state
    // const [addLinkFirstName, setAddLinkFirstName] = useState("")
    // const [addLinkLastName, setAddLinkLastName] = useState("")
    // const [addLinkEmail, setAddLinkEmail] = useState("")
    // const [addOriginAirport, setAddOriginAirport] = useState("")
    // const [addReturnAirport, setAddReturnAirport] = useState("")
    // const [addMaxCost, setAddMaxCost] = useState(0.0)

    // Edit attendee form state
    const [editLinkID, setEditLinkID] = useState("")
    const [editLinkFirstName, setEditLinkFirstName] = useState("")
    const [editLinkLastName, setEditLinkLastName] = useState("")
    const [editLinkEmail, setEditLinkEmail] = useState("")
    const [editOriginAirport, setEditOriginAirport] = useState("")
    const [editReturnAirport, setEditReturnAirport] = useState("")
    const [editMaxCost, setEditMaxCost] = useState(0.0)
    const [editExpirationDate, setEditExpirationDate] = useState("")
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [validated, setValidated] = useState(false)

    // Add links form state
    const [numNewLinks, setNumNewLinks] = useState(1)

    // Import CSV state
    const [importValidated, setImportValidated] = useState(false)
    const [vbtnDisabled, setVBtnDisabled] = useState(false)
    const [file, setFile] = useState()
    const fileReader = new FileReader()

    // Show/hide modal state
    const [showEditEventModal, setShowEditEventModal] = useState(false)
    const [showDeleteEventModal, setShowDeleteEventModal] = useState(false)
    const [showAddModal, setShowAddModal] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showEmailModal, setShowEmailModal] = useState(false)
    const [showPayModal, setShowPayModal] = useState(false)
    const [showViewFlightModal, setShowViewFlightModal] = useState(false)
    const [showPayFlightModal, setShowPayFlightModal] = useState(false)
    const [showImportModal, setShowImportModal] = useState(false)

    // Auth state
    const [authToken, setAuthToken] = useState("")
    const [idToken, setIDToken] = useState("")

    // View flight modal state
    const [flight, setFlight] = useState(null)
    const [additionalInfo, setAdditionalInfo] = useState("")

    // Rate limit the email button
    const [isEmailSent, setIsEmailSent] = useState(false)

    const handleEditEventShow = () => {
        setEventName(event.event_name)
        setEventLocation(event.event_location)
        setEventStartDate(event.event_start)
        setEventEndDate(event.event_end)
        setEventFlyInDate(event.fly_in_date)
        setEventFlyOutDate(event.fly_out_date)

        setShowEditEventModal(true)
    }

    const handleDeleteEventShow = () => {
        setShowDeleteEventModal(true)
    }

    const handleEditEventHide = () => {
        setShowEditEventModal(false)
    }

    const handleDeleteEventHide = () => {
        setShowDeleteEventModal(false)
    }

    const handleAddModalShow = () => {
        setShowAddModal(true)
    }

    const handleEditModalShow = (attendee) => {
        setEditLinkID(attendee.link_id)
        setEditLinkFirstName(attendee.link_first_name)
        setEditLinkLastName(attendee.link_last_name)
        setEditLinkEmail(attendee.link_email)
        setEditOriginAirport(attendee.origin_airport)
        setEditReturnAirport(attendee.return_airport)
        setEditMaxCost(attendee.max_cost)
        setEditExpirationDate(attendee.expiration_date)

        setShowEditModal(true)
        setActiveAttendee(attendee)
    }

    const handleShowViewFlightModalShow = (attendee) => {
        fetch(baseUrl + "/client/lookup-order?" + new URLSearchParams({
            link_id: attendee.link_id,
        }))
            .then(results => results.json())
            .then(data => {
                setFlight(data)
                setAdditionalInfo(attendee.additional_info)
            })
        setShowViewFlightModal(true)
    }

    const handleDeleteModalShow = (attendee) => {
        setShowDeleteModal(true)
        setActiveAttendee(attendee)
    }

    const handlePayModalShow = () => {
        setShowPayModal(true)
    }

    const handlePayFlightModalShow = () => {
        setShowPayFlightModal(true)
    }

    const handleImportModalShow = () => {
        setShowImportModal(true)
    }

    const handleAddModalHide = () => {
        setShowAddModal(false)
        setActiveAttendee("")
    }

    const handleEditModalHide = () => {
        setShowEditModal(false)
        setActiveAttendee("")
    }

    const handleDeleteModalHide = () => {
        setShowDeleteModal(false)
        setActiveAttendee("")
    }

    const handleShowViewFlightModalHide = () => {
        setShowViewFlightModal(false)
        setFlight(null)
        setAdditionalInfo("")
    }

    const handlePayModalHide = () => {
        setShowPayModal(false)
    }

    const handlePayFlightModalHide = () => {
        setShowPayFlightModal(false)
    }

    const handleImportModalHide = () => {
        setShowImportModal(false)
    }

    useEffect(() => {
        if (!eventId) {
            navigation('/dashboard')
        } else {
            const user = Pool.getCurrentUser()
            if (user) {
                user.getSession((err, session) => {
                    if (err) {
                        navigation('/sign-in')
                    } else {
                        setAuthToken(session.accessToken.jwtToken)
                        setIDToken(session.idToken.jwtToken)
                        setUserName(session.idToken.payload.email)
                        setOrganizationName(session.idToken.payload["custom:organization_name"])
                    }
                })
            } else {
                navigation('/sign-in')
            }
        }
    }, [])

    useEffect(() => {
        if (authToken) {
            fetch(baseUrl + '/admin/get-single-event?' + new URLSearchParams({
                event_id: eventId
            }), {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(results => results.json())
                .then(data => {
                    setEvent(data)
                })
        }
    }, [authToken])

    useEffect(() => {
        if (authToken) {
            fetch(baseUrl + '/admin/get-all-people?' + new URLSearchParams({
                event_id: eventId
            }), {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(results => results.json())
                .then(data => {
                    setAttendeeList(data)
                })
        }
    }, [authToken])


    function handleRefresh() {
        fetch(baseUrl + '/admin/get-all-people?' + new URLSearchParams({
            event_id: eventId
        }), {
            headers: {
                'Authorization': `Bearer ${authToken}`
            }
        })
            .then(results => results.json())
            .then(data => {
                setAttendeeList(data)
            })
    }

    function handleLogout() {
        logout()
        navigation('/sign-in')
    }

    // when `isBatch` is true, the method is called from the "Email All Attendees" button
    function handleSendEmail(attendee, isBatch) {
        // return immediately if already booked flight
        if (attendee.is_booked) {
            return
        }

        if (!isBatch) {
            setActiveAttendee(attendee)
        }

        const emailParams = {
            userFirstName: attendee.link_first_name,
            userLastName: attendee.link_last_name,
            userEmail: attendee.link_email,
            eventName: event.event_name,
            orgName: organizationName,
            link_id: attendee.link_id,
        }

        // Only email attendees that have not yet booked
        if (isBatch && !attendee.is_booked) {
            fetch(sendEmailUrl + '/send-email-book', {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken
                },
                body: JSON.stringify(emailParams)
            })

            // in normal mode, send the email and show the modal as well
        } else {
            fetch(sendEmailUrl + '/send-email-book', {
                method: "POST",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': idToken
                },
                body: JSON.stringify(emailParams)
            })
                .then(setShowEmailModal(true))
        }
    }

    const handleEmailModalHide = () => {
        setShowEmailModal(false)
        setActiveAttendee("")
    }

    function handleEditEventHideEdit(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            setEBtnDisabled(true)

            const eventData = {
                event_name: eventName,
                event_location: eventLocation,
                event_img: "",
                event_organizer: event.event_organizer,
                event_start: eventStartDate,
                event_end: eventEndDate,
                fly_in_date: eventFlyInDate,
                fly_out_date: eventFlyOutDate,
                event_id: event.event_id,
                org_id: event.org_id,
            }
            fetch(baseUrl + '/admin/edit-event', {
                method: "PUT",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(eventData)
            })
                .then(results => results.json())
                .then(data => {
                    setEBtnDisabled(false)
                    setEValidated(false)
                    fetch(baseUrl + '/admin/get-single-event?' + new URLSearchParams({
                        event_id: eventId
                    }), {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        }
                    })
                        .then(results => results.json())
                        .then(data => {
                            setEvent(data)
                        })
                    handleEditEventHide()
                })
        }
        setEValidated(true)
    }

    function handleEventCloseEdit(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            setBtnDisabled(true)

            const linkData = {
                link_id: activeAttendee.link_id,
                link_first_name: editLinkFirstName,
                link_last_name: editLinkLastName,
                link_email: editLinkEmail,
                origin_airport: editOriginAirport,
                return_airport: editReturnAirport,
                is_booked: false,
                order_pnr: null,
                order_id: null,
                max_cost: editMaxCost,
                event_id: activeAttendee.event_id,
                expiration_date: editExpirationDate,
            }
            fetch(baseUrl + '/admin/edit-person', {
                method: "PUT",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(linkData)
            })
                .then(results => results.json())
                .then(data => {
                    fetch(baseUrl + '/admin/get-all-people?' + new URLSearchParams({
                        event_id: eventId
                    }), {
                        headers: {
                            'Authorization': `Bearer ${authToken}`
                        }
                    })
                        .then(results => results.json())
                        .then(data => {
                            setAttendeeList(data)
                        })
                    setBtnDisabled(false)
                    setValidated(false)
                    handleEditModalHide()
                })
        }
        setValidated(true)
    }

    function handleEventDelete() {
        attendeeList.forEach((attendee) => {
            fetch(baseUrl + '/admin/delete-person', {
                method: "DELETE",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(attendee)
            })
        })

        fetch(baseUrl + '/admin/delete-event', {
            method: "DELETE",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(event)
        })
            .then(() => {
                navigation('/dashboard')
            })
    }

    function handleEventCloseDelete() {
        fetch(baseUrl + '/admin/delete-person', {
            method: "DELETE",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authToken}`
            },
            body: JSON.stringify(activeAttendee)
        }).then(() => {
            fetch(baseUrl + '/admin/get-all-people?' + new URLSearchParams({
                event_id: eventId
            }), {
                headers: {
                    'Authorization': `Bearer ${authToken}`
                }
            })
                .then(results => results.json())
                .then(data => {
                    setAttendeeList(data)
                })
            handleDeleteModalHide()
        })
    }

    function handleAddNewLinks(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            setBtnDisabled(true)
            navigation('/add-links', { state: { num_links: numNewLinks, event_id: eventId, email: userName } })
        }
    }

    function handlePayFlightCard() {
        navigation('/prepay-flights', { state: { amount_flights: calcAmountDue(), amount_owed: calcAmountDueCard(), event_id: eventId, email: userName } })
    }

    function calcTotal() {
        var total = 0.0
        for (var i = 0; i < attendeeList.length; i++) {
            total += parseFloat(attendeeList[i]["max_cost"])
        }
        return total
    }

    function calcAmountDue() {
        return (calcTotal() * event.pay_now_percent / 100) - event.amount_paid
    }

    function calcAmountDueCard() {
        return calcAmountDue() * 1.05
    }

    function isPayFlightsActive() {
        return (event.pay_now_percent === 0 || ((calcTotal() * event.pay_now_percent / 100) - event.amount_paid) <= 0)
    }

    function handleCSVOnChange(e) {
        setFile(e.target.files[0])
    }

    function arrayEquals(a, b) {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    function handleImportAttendees(e) {
        const form = e.currentTarget
        if (form.checkValidity() === false) {
            e.preventDefault()
            e.stopPropagation()
        } else {
            e.preventDefault()
            setVBtnDisabled(true)
            // Parse CSV
            if (file) {
                fileReader.onload = function (e) {
                    const csvOutput = e.target.result
                    const csvHeader = csvOutput.slice(0, csvOutput.indexOf("\r\n")).split(",");
                    const csvRows = csvOutput.slice(csvOutput.indexOf("\r\n")).split("\r\n").slice(1, -1);

                    // Check header format
                    const checkHeader = ["first_name", "last_name", "email", "origin_airport_code", "return_airport_code", "max_cost_dollars"]
                    if (!arrayEquals(csvHeader, checkHeader)) {
                        alert("Invalid CSV format")
                        return
                    }

                    // Check row count
                    // if (csvRows.length > (event.num_paid_links - attendeeList.length)) {
                    //     alert("Importing too many attendees.\nPlease pay for extra attendees first.")
                    //     return
                    // }

                    // Parse rows
                    var peopleData = []
                    for (let idx in csvRows) {
                        let row = csvRows[idx]
                        const info = row.split(",")

                        const linkData = {
                            link_id: crypto.randomUUID(), // TODO: move this to server side generation
                            link_first_name: info[0],
                            link_last_name: info[1],
                            link_email: info[2],
                            origin_airport: info[3],
                            return_airport: info[4],
                            is_booked: false,
                            order_pnr: null,
                            order_id: null,
                            max_cost: info[5],
                            event_id: eventId,
                            expiration_date: null
                        }
                        peopleData.push(linkData)
                    }

                    // Upload to server
                    fetch(baseUrl + '/admin/batch-add-person', {
                        method: "POST",
                        mode: "cors",
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${authToken}`
                        },
                        body: JSON.stringify(peopleData)
                    }).then(() => {
                        fetch(baseUrl + '/admin/get-all-people?' + new URLSearchParams({
                            event_id: eventId
                        }), {
                            headers: {
                                'Authorization': `Bearer ${authToken}`
                            }
                        })
                            .then(results => results.json())
                            .then(data => {
                                setAttendeeList(data)
                            })
                        handleImportModalHide()
                        setVBtnDisabled(false)
                        setImportValidated(false)
                    })
                }
                fileReader.readAsText(file)
            }
            setVBtnDisabled(false)
        }
        setImportValidated(true)
    }

    function handleExportAttendees() {
        let rows = []
        let header_row = ["first_name", "last_name", "email", "origin_airport", "return_airport", "is_booked", "max_cost_dollars"]
        rows.push(header_row)
        for (var i = 0; i < attendeeList.length; i++) {
            let row = []
            row.push(attendeeList[i]["link_first_name"])
            row.push(attendeeList[i]["link_last_name"])
            row.push(attendeeList[i]["link_email"])
            row.push(attendeeList[i]["origin_airport"])
            row.push(attendeeList[i]["return_airport"])
            row.push(attendeeList[i]["is_booked"])
            row.push(attendeeList[i]["max_cost"])
            rows.push(row)
        }
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n")

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "planelinks_export.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "planelinks_export.csv"
    }

    function downloadCSVTemplate() {
        let rows = []
        let header_row = ["first_name", "last_name", "email", "origin_airport_code", "return_airport_code", "max_cost_dollars"]
        rows.push(header_row)
        let csvContent = "data:text/csv;charset=utf-8,"
            + rows.map(e => e.join(",")).join("\n")

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "planelinks_import_template.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "planelinks_import_template.csv"
    }

    const OverlayTooltip = ({ id, children, title }) => (
        <OverlayTrigger overlay={<Tooltip id={id}>{title}</Tooltip>}>
            {children}
        </OverlayTrigger>
    )

    return (
        <div>
            <Container>
                <Navbar className='navbar-no-bg'>
                    <Navbar.Brand href="/">✈️ <b>Planelinks</b> | Manage Event</Navbar.Brand>
                    <Navbar.Collapse className="justify-content-end">
                        <Link to="/dashboard" className="no-style-link-light" state={{ email: userName, organizationName: organizationName }}>
                            <Button variant="success" className="log-in-button" style={{ marginRight: '32px' }}>
                                Back to Dashboard
                            </Button>
                        </Link>
                        <Button variant="dark" className="log-in-button" onClick={() => handleLogout()}>
                            Log Out
                        </Button>
                    </Navbar.Collapse>
                </Navbar>
                <div className="admin-dashboard-landing">
                    <Row>
                        <Col md={6}>
                            <h3>Manage Your Event:</h3>
                            <h4>{event.event_name}</h4>
                            <p>Location: {event.event_location}<br />
                                Airport: {event.pref_airports}<br />
                                {event.event_start} to {event.event_end} (Event)<br />
                                {event.fly_in_date} to {event.fly_out_date} (Flights)</p>
                            <Button variant="success" className="log-in-button" style={{ marginRight: "16px" }} onClick={() => handleEditEventShow()}>
                                Edit Event
                            </Button>
                            <Button variant="danger" className="log-in-button" onClick={() => handleDeleteEventShow()}>
                                Delete Event
                            </Button>
                        </Col>
                        <Col md={6}>
                            <h3>At a Glance:</h3>
                            <Row>
                                <Col>
                                    {/* <p>Number of attendees: {attendeeList.length}<br />
                                        Number of attendees paid for: {event.num_paid_links}</p>
                                    <Button variant="success" className="log-in-button" onClick={() => handlePayModalShow()}>
                                        Pay for Links
                                    </Button> */}
                                    <p>Number of attendees: {attendeeList.length}</p>
                                </Col>
                                <Col>
                                    <p>Percentage paid upfront: {event.pay_now_percent}%<br />
                                        Total budget: ${calcTotal()}<br />
                                        Amount paid: ${event.amount_paid}<br />
                                        Amount due: ${calcAmountDue()}</p>
                                    <Button variant="success" className="log-in-button" onClick={() => handlePayFlightModalShow()} disabled={isPayFlightsActive()}>
                                        Pay for Flights
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <hr />
                <div style={{ paddingTop: "2vh" }}>
                    <h3>Attendees:</h3>
                    {/* <Link to="/add-attendee" className="no-style-link-light" state={{ event_name: event.event_name, event_id: eventId }} onClick={(e) => event.num_paid_links <= attendeeList.length ? e.preventDefault() : e}>
                        <Button variant="success" className="log-in-button" style={{ marginTop: '1vh', marginBottom: '1vh', marginRight: '8px' }} disabled={event.num_paid_links <= attendeeList.length}>
                            Add Attendee +
                        </Button>
                    </Link> */}
                    <Link to="/add-attendee" className="no-style-link-light" state={{ event_name: event.event_name, event_id: eventId }}>
                        <Button variant="success" className="log-in-button" style={{ marginTop: '1vh', marginBottom: '1vh', marginRight: '8px' }}>
                            Add Attendee +
                        </Button>
                    </Link>
                    {/* <Button variant="success" className="log-in-button" style={{ marginTop: '1vh', marginBottom: '1vh', marginRight: '8px' }} onClick={() => handleImportModalShow()} disabled={event.num_paid_links <= attendeeList.length}>
                        Import Attendees
                    </Button> */}
                    <Button variant="success" className="log-in-button" style={{ marginTop: '1vh', marginBottom: '1vh', marginRight: '8px' }} onClick={() => handleImportModalShow()}>
                        Import Attendees
                    </Button>
                    <Button variant="warning" className="log-in-button" style={{ marginTop: '1vh', marginBottom: '1vh', marginRight: '8px' }} onClick={() => handleExportAttendees()} disabled={attendeeList.length <= 0}>
                        Export Attendees
                    </Button>
                    <Button className="log-in-button" onClick={() => { attendeeList.forEach((attendee) => handleSendEmail(attendee, true)); setIsEmailSent(true) }} disabled={!isPayFlightsActive() || attendeeList.length <= 0 || isEmailSent} style={{ marginTop: '1vh', marginBottom: '1vh', marginRight: '8px' }}>
                        {isEmailSent ? "All Emails Sent!" : "Email All Attendees"}
                    </Button>
                    <Button variant="dark" className="log-in-button" onClick={() => handleRefresh()}>
                        Refresh List
                    </Button>
                    <div className="attendee-table">
                        <Table responsive hover className="text-align-center">
                            <thead>
                                <tr>
                                    <OverlayTooltip title="First/given name of attendee" id="t-1">
                                        <th>First Name</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Last/family name of attendee" id="t-2">
                                        <th>Last Name</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Email address of attendee" id="t-3">
                                        <th>Email</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Airport the attendee is flying from" id="t-4">
                                        <th>Origin Airport</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Airport the attendee is returning to" id="t-5">
                                        <th>Return Airport</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Maximum budget for this attendee" id="t-6">
                                        <th>Max Cost</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Has this attendee booked a flight?" id="t-7">
                                        <th>Booked Flight</th>
                                    </OverlayTooltip>
                                    <OverlayTooltip title="Click link to manage/edit attendee" id="t-8">
                                        <th>Manage Attendee</th>
                                    </OverlayTooltip>
                                </tr>
                            </thead>
                            <tbody>
                                {attendeeList.map(function (attendee) {
                                    return (
                                        <tr key={attendee.link_id}>
                                            <td>{attendee.link_first_name}</td>
                                            <td>{attendee.link_last_name}</td>
                                            <td>{attendee.link_email}</td>
                                            <td>{attendee.origin_airport}</td>
                                            <td>{attendee.return_airport}</td>
                                            <td>${attendee.max_cost}</td>
                                            <td>{attendee.is_booked === true ? "✔️" : "❌"}</td>
                                            <td>{attendee.is_booked === true ?
                                                <Button onClick={() => handleShowViewFlightModalShow(attendee)}>
                                                    View Flight
                                                </Button> :
                                                <div>
                                                    <Button variant="success" style={{ marginRight: "8px" }} onClick={() => handleEditModalShow(attendee)}>
                                                        Edit
                                                    </Button>
                                                    <Button variant="danger" style={{ marginRight: "8px" }} onClick={() => handleDeleteModalShow(attendee)}>
                                                        Delete
                                                    </Button>
                                                    <Button onClick={() => handleSendEmail(attendee, false)} disabled={!isPayFlightsActive()}>
                                                        Email
                                                    </Button>
                                                </div>
                                            }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </div>
                <hr />
                <AdminFooter />
            </Container>
            <Modal size="lg" show={showEditEventModal} onHide={handleEditEventHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Make changes below and save.</h5>
                    <Form noValidate onSubmit={(e) => handleEditEventHideEdit(e)} validated={evalidated}>
                        <Form.Group className='mb-3' controlId='formBasicEventName' onChange={(e) => setEventName(e.target.value)}>
                            <Form.Label>Event Name</Form.Label>
                            <Form.Control type="text" defaultValue={eventName} required />
                            <Form.Control.Feedback type="invalid">Please provide an event name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicEventLocation' onChange={(e) => setEventLocation(e.target.value)}>
                            <Form.Label>Event Location</Form.Label>
                            <Form.Control type="text" defaultValue={eventLocation} required />
                            <Form.Control.Feedback type="invalid">Please provide an event location.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicEventStart' onChange={(e) => setEventStartDate(e.target.value)}>
                            <Form.Label>Event Start</Form.Label>
                            <Form.Control type="date" defaultValue={eventStartDate} required />
                            <Form.Control.Feedback type="invalid">Please provide an event start date.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicEventEnd' onChange={(e) => setEventEndDate(e.target.value)}>
                            <Form.Label>Event End</Form.Label>
                            <Form.Control type="date" defaultValue={eventEndDate} required />
                            <Form.Control.Feedback type="invalid">Please provide an event end date.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicFlyInDate' onChange={(e) => setEventFlyInDate(e.target.value)}>
                            <Form.Label>Fly In Date</Form.Label>
                            <Form.Control type="date" defaultValue={eventFlyInDate} required />
                            <Form.Control.Feedback type="invalid">Please provide a fly in date.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicFlyOutDate' onChange={(e) => setEventFlyOutDate(e.target.value)}>
                            <Form.Label>Fly Out Date</Form.Label>
                            <Form.Control type="date" defaultValue={eventFlyOutDate} required />
                            <Form.Control.Feedback type="invalid">Please provide a fly out date.</Form.Control.Feedback>
                        </Form.Group>
                        <div className="text-align-center">
                            <Button variant="success" type="submit" className="log-in-button" disabled={ebtnDisabled}>
                                Edit Event
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleEditEventHide}>
                        Back to Dashboard
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={showDeleteEventModal} onHide={handleDeleteEventHide}>
                <Modal.Header>
                    <Modal.Title>Delete Event</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Are you sure?</h3>
                    This action cannot be reversed. Already booked flights will not be refunded or cancelled.
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleEventDelete}>
                        Yes, delete
                    </Button>
                    <Button variant="dark" onClick={handleDeleteEventHide}>
                        No, return
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={showEmailModal} onHide={handleEmailModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Email Sent</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Reminder email has been sent to <b>{activeAttendee.link_first_name} {activeAttendee.link_last_name}</b> ({activeAttendee.link_email}).
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleEmailModalHide}>
                        Back to Dashboard
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={showAddModal} onHide={handleAddModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Attendee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    TODO Migration in progress
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showEditModal} onHide={handleEditModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Attendee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Attendee ID: {!isPayFlightsActive() ? "Attendee ID not active" : editLinkID}</p>
                    {!isPayFlightsActive() ? <></> : <p>Attendee <Link to={`https://planelinks.com/book-flight/?id=${editLinkID}`} target="_blank" rel="noreferrer">link</Link></p>}
                    <h5>Make changes below and save.</h5>
                    <Form noValidate onSubmit={(e) => handleEventCloseEdit(e)} validated={validated}>
                        <Form.Group className="mb-3" controlId='formBasicFirstName' onChange={(e) => { setEditLinkFirstName(e.target.value) }}>
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text" defaultValue={editLinkFirstName} required />
                            <Form.Control.Feedback type='invalid'>Please provide a first name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicLastName' onChange={(e) => { setEditLinkLastName(e.target.value) }}>
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type='text' defaultValue={editLinkLastName} required />
                            <Form.Control.Feedback type='invalid'>Please provide a last name.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicEmail' onChange={(e) => { setEditLinkEmail(e.target.value) }}>
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control type='email' defaultValue={editLinkEmail} required />
                            <Form.Control.Feedback type='invalid'>Please provide a valid email address.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicOriginAirport' onChange={(e) => { setEditOriginAirport(e.target.value) }}>
                            <Form.Label>Origin Airport</Form.Label>
                            <Form.Control type='text' defaultValue={editOriginAirport} required />
                            <Form.Control.Feedback type='invalid'>Please provide a valid origin airport code.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicReturnAirport' onChange={(e) => { setEditReturnAirport(e.target.value) }}>
                            <Form.Label>Return Airport</Form.Label>
                            <Form.Control type='text' defaultValue={editReturnAirport} required />
                            <Form.Control.Feedback type='invalid'>Please provide a valid return airport code.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className='mb-3' controlId='formBasicMaxBudget' onChange={(e) => { setEditMaxCost(e.target.value) }}>
                            <Form.Label>Maximum Ticket Cost (Round Trip)</Form.Label>
                            <Form.Control type='number' step='0.01' defaultValue={editMaxCost} required></Form.Control>
                            <Form.Control.Feedback type='invalid'>Please provide a valid amount.</Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicExpirationDate" onChange={(e) => { setEditExpirationDate(e.target.value) }}>
                            <Form.Label>Expiration Date</Form.Label>
                            <Form.Control type='date' placeholder="Expiration Date" defaultValue={editExpirationDate} required />
                            <Form.Control.Feedback type='invalid'>Please provide an expiration date.</Form.Control.Feedback>
                        </Form.Group>
                        <div className='text-align-center'>
                            <Button variant='success' type='submit' className='log-in-button' disabled={btnDisabled}>
                                Edit Attendee
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleEditModalHide}>
                        Back to Dashboard
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={showDeleteModal} onHide={handleDeleteModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete Attendee</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>Are you sure?</h3>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleEventCloseDelete}>
                        Yes, delete
                    </Button>
                    <Button variant="dark" onClick={handleDeleteModalHide}>
                        No, return
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size="lg" show={showPayModal} onHide={handlePayModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Add More Attendee Links</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>How many more attendees would you like to add?</h3>
                    <Form noValidate onSubmit={(e) => handleAddNewLinks(e)} validated={validated}>
                        <Form.Group className='mb-3' controlId='formBasicNewLinks' onChange={(e) => { setNumNewLinks(e.target.value) }}>
                            <Form.Label>Number of new attendee links</Form.Label>
                            <Form.Control type='number' step='1' defaultValue={1} min="1" required></Form.Control>
                            <Form.Control.Feedback type='invalid'>Please provide a valid amount.</Form.Control.Feedback>
                        </Form.Group>
                        <div className='text-align-center'>
                            <Button variant='success' type='submit' className='log-in-button' disabled={btnDisabled}>
                                Add Links
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal size="lg" show={showPayFlightModal} onHide={handlePayFlightModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Pay for Flights</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h3>How would you like to pay?</h3>
                    <h5>Pay by bank transfer:</h5>
                    <p>To pay for flights, send us a bank transfer using the details below. Once you've sent your bank transfer, please send us an email at <Link to="mailto:payments@planelinks.com">payments@planelinks.com</Link> to let us know. This will help us to process your payment as quickly as possible.</p>
                    <ul>
                        <li>Beneficiary: &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Planelinks, Inc.</li>
                        <li>Account number: &emsp;&emsp;&emsp;&emsp; 202424542624</li>
                        <li>ABA routing number: &emsp; &emsp; 091311229</li>
                        <li>Bank Address:<br />
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Choice Financial Group<br />
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;4501 23rd Avenue S<br />
                            &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;Fargo, ND 58104
                        </li>
                        <li>BIC/SWIFT: &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;CHFGUS44021</li>
                    </ul>
                    <h5>Pay by card (5% card processing fee applies):</h5>
                    <br />
                    <Button variant="success" className="log-in-button" onClick={() => handlePayFlightCard()}>Pay by card</Button>
                    <br />
                    <br />
                </Modal.Body>
            </Modal>
            <Modal size='lg' show={showViewFlightModal} onHide={handleShowViewFlightModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>View Booked Flight</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Total price of ticket ${flight && flight.outbound_flight.price}</p>
                    <p>Additional information: {additionalInfo}</p>
                    {flight && (<Table responsive>
                        <thead>
                            <tr>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr key={flight.outbound_flight.offer_id}>
                                <td style={{ textAlign: "center" }}><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.outbound_flight.airline_img} alt="Airline Logo" /></td>
                                <td><p><b>{convertDateFormat(flight.outbound_flight.departure_time)} - {convertDateFormat(flight.outbound_flight.arrival_time)}</b><br />{flight.outbound_flight.airline_name} · {flight.outbound_flight.market_flight_no.join(", ")}</p></td>
                                <td><p><b>{transformDuration(flight.outbound_flight.duration)}</b><br />{flight.outbound_flight.origin_airport}-{flight.outbound_flight.destination_airport}</p></td>
                                <td><p>{calculateLayover(flight.outbound_flight.layover_airport)}</p></td>
                            </tr>
                            <tr key={flight.return_flight.offer_id}>
                                <td style={{ textAlign: "center" }}><img style={{ padding: "8px", height: "48px", width: "48px" }} src={flight.return_flight.airline_img} alt="Airline Logo" /></td>
                                <td><p><b>{convertDateFormat(flight.return_flight.departure_time)} - {convertDateFormat(flight.return_flight.arrival_time)}</b><br />{flight.return_flight.airline_name} · {flight.return_flight.market_flight_no.join(", ")}</p></td>
                                <td><p><b>{transformDuration(flight.return_flight.duration)}</b><br />{flight.return_flight.origin_airport}-{flight.return_flight.destination_airport}</p></td>
                                <td><p>{calculateLayover(flight.return_flight.layover_airport)}</p></td>
                            </tr>
                        </tbody>
                    </Table>)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleShowViewFlightModalHide}>
                        Back to Dashboard
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal size='lg' show={showImportModal} onHide={handleImportModalHide}>
                <Modal.Header closeButton>
                    <Modal.Title>Import Attendees</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Step 1: Download CSV Template</h4>
                    <Button variant="success" className="log-in-button" style={{ marginTop: '1vh', marginBottom: '1vh', marginRight: '8px' }} onClick={() => downloadCSVTemplate()}>
                        Download
                    </Button>
                    <br />
                    <br />
                    <h4>Step 2: Import CSV File</h4>
                    <Form noValidate onSubmit={(e) => handleImportAttendees(e)} validated={importValidated}>
                        <Form.Group controlId="formFile" className="mb-3" onChange={handleCSVOnChange}>
                            <Form.Control type="file" accept=".csv" required />
                            <Form.Control.Feedback type="invalid">Please upload a CSV file.</Form.Control.Feedback>
                        </Form.Group>
                        <Button variant='success' type='submit' className='log-in-button' disabled={vbtnDisabled}>
                            Upload Attendees
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="dark" onClick={handleImportModalHide}>
                        Back to Dashboard
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AdminManageEventPage
